<template>
	<div class="yrittajyys">
		<div class="container-fluid">
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-8">
					<img class="img-fluid fluid-grow" alt="" src="../../assets/kolmeihmistatoimistotyopoydanaaressa.webp" style="width: 100%;">
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid" style="margin-top: 25px;">
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h2>Yrittäjyystietoa</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<h3>Osaaminen</h3>
				</div>
				<div class="col"></div>
			</div>
			<div class="row" style="margin-top: 15px;">
				<div class="col"></div>
				<div class="col-sm-8">
					<p>Yrittäjäksi aikovalla on oltava idea siitä minkälaista tuotetta aikoo myydä eli jos sinulla tai tuntemillasi henkilöillä on jotain sellaista osaamista tai taitoa tuottaa jotakin, joka selkeästi puuttuu markkinoilta, on laadultaan tai toiminnaltaan huonoa / kallista ja/tai josta olisi hyötyä ihmiskunnalle.</p>
					<p>Yrittämisen tietotaitoa voi syventää osallistumalla esimerkiksi yrittäjäksi aikoville suunnatulle kurssille (kts. TE-toimisto:Haussa oleva työvoimakoulutus / Yrittäjät). Liikkeelle lähtöön ei tarvita vuosien koulutusta, mutta aietta on hyvä testata realistisilla kysymyksillä ja laatia nk. liiketoimintasuunnitelma. Kunkin talousalueen kehittämisestä kiinnostuneet tahot (esim. Turussa ”Potkuri” / Turku Science Park) ovat edelläkävijöitä ja avustavat mielellään tarvittavan tiedon hankkimisessa ja liiketoimintasuunnitelman pohdinnassa.</p>
					<p>Kokonaisuuden rakentamiseksi on mietittävä yksityiskohtia; kuten kohdealuetta (Suomi/ EU / muut maat), lainsäädäntöä (elintarvikkeet!), yritysmuotoa (toiminimi /r.y./ OY), yrityksen nimeä (aakkosten alkuun! /www-sivustoa/-osoitetta), verotuslainsäädäntöä (Y-tunnus), kirjanpitoa, tuotantotiloja, henkilöstöä, aikataulutusta, omaa eläkettä tai sairausvakuutusta ja ennen kaikkea: kokonaisrahoitusta (ja lainaamansa rahan takaisinmaksuaikataulua ja ehtoja). Mahdollisesta pienimuotoisesta ´starttirahasta´ voi keskustella myös TE-toimiston kanssa eli harkita, josko työttömyysajalla voisi tehdä jo työtä oman yrityksensä perustamiseksi.</p>
					<p>Yrittäjät jakavat mielellään tietoa, joten katso myös alla olevat linkit! Kaikkea ei tarvitse tehdä yksin ja esimerkiksi kirjanpitopalveluita voikin hankkia muualta. Yrittäminen voi olla myös nk. ´kevyt yrittämistä´ tai toimimista toiminimellä (´freelancer´; Eezy). Tilapäisten töiden laskutuskin onnistuu nykyisin kevyemmillä alustoilla (esim. UKKO). Lisäksi, yrityksiä on myös myynnissä eli kaikkea ei tarvitse rakentaa alusta saakka vaan tarjolla on myös yrityksiä, jonka yrittäjä haluaa myydä esim. eläkkeelle jäämisen tai yllättävän sairauden takia (katso alla: Yrityspörssi).</p>
					<p>Kenelle yrittäminen sopii? On ilman muuta selvää, että on sellaisia idearikkaita ja määrätietoisia ihmisiä, joille itsenäisyys ja vapaus on jo itsessään niin suuri palkinto, etteivät he alkuun päästyään enää haluaisikaan työskennellä kenenkään toisen alaisuudessa! Onnistuneen yrittämisen ytimessä saattaa olla se yksi hyvä idea, johon yrittäjä uskoo niin, että jaksaa pistää kaiken osaamisensa ja energiansa - ja jaksaa luotsata yritystään läpi vaikeuksienkin. Tavallisimmat sudenkuopat lienevät jo monien tiedossa esim. riittämätön rahoitus, suuret tuotantokustannukset tai liian nopea laajeneminen, mutta erityisesti ensimmäiset vuodet (&lt;1-5 vuotta) ovat tavallisesti ne kriittisimmät ja vaativat visionääriltään erittäin lujaa motivaatiota ja uskoa asiaansa. Loistavia oivalluslamppuja ja liikeideoita on meillä myös syntynyt useamman erilaisen toimijan liittyessä yhteen ja yhdistämällä osaamisensa. Apua ja neuvoja kannattaa kysellä alan muilta toimijoilta. Vain kauas katsoen pääsee perille!</p>
					<p>Rahoituksesta: Alakohtaiset erot yrityksen perustamisen hintalapulle ovat huomattavat ja riippuvat luonnollisesti tuotteen tyypistä - onko kyseessä alkutuotantoa, lähdetäänkö liikkeelle teknisesti vaativasta osasta tai koko laitteesta vai onko tuote konsultointia tai palvelua jne. FINNVERA on Suomessa tyypillinen pienyritysten rahoittajataho, mutta lainamäärät eivät ole kovin suuria (&lt; 50 000 €) eli suuren riskin rahoitusta on haettava muualta. Jos tähtää kansainväliseen tuotteeseen on TEKES (Business Finland) yksi merkittävimmistä kotimaisista toimijoista. Esimerkkinä peliyhtiö Rovio, jota TEKES on tukenut merkittävällä rahoituksella yhtiön matkalla menestykseen. Katso myös Euroopan Unionin tarjoamat rahoitusmahdollisuudet (ELY-keskukset / Rakennerahoitus). Viime vuosina huomiota ovat saaneet myös nk. bisnesenkelit eli yksityisten rahoituslähteiden käyttö ja verkostoituminen (kts. FIBAN-sivustolla opas rahoituksen hakemiseen). Yksityisten tai pääomasijoittajien sijoitussumma alkaville yrityksille oli jo vuonna 2017 yhteensä 349 miljoonaa euroa, joten puhutaan jo todella merkittävistä sijoituksista hyviin ideoihin ja trendi on nouseva!</p>
					<p>Linkit:<br>
					ELY-keskukset/Varsinais-Suomi: <a href="http://www.ely-keskus.fi/web/ely/ely-varsinais-suomi;jsessionid=B1A1797B2E49DCB908BE3EE535EBB528?p_p_id=122_INSTANCE_aluevalinta&p_p_lifecycle=0&p_p_state=normal&p_p_mode=view&p_r_p_564233524_resetCur=true&p_r_p_564233524_categoryId=14406">http://www.ely-keskus.fi/web/ely/ely-varsinais-suomi;jsessionid=B1A1797B2E49DCB908BE3EE535EBB528?p_p_id=122_INSTANCE_aluevalinta&p_p_lifecycle=0&p_p_state=normal&p_p_mode=view&p_r_p_564233524_resetCur=true&p_r_p_564233524_categoryId=14406</a><br>

					EU Rakennerahoitus: <a href="http://www.rakennerahastot.fi/">http://www.rakennerahastot.fi/</a><br>

					POTKURI / Turun seutu: <a href="https://turkubusinessregion.com/palvelut/alkavan-yrittajan-palvelut/">https://turkubusinessregion.com/palvelut/alkavan-yrittajan-palvelut/</a><br>

					FINNVERA: <a href="https://yrityksen-perustaminen.net/finnvera/">https://yrityksen-perustaminen.net/finnvera/</a><br>

					TEKES /Business Finland: <a href="https://www.tek.fi/fi/tyoelama/yrittaja/yrittajan-palvelutarjotin/tekes">https://www.tek.fi/fi/tyoelama/yrittaja/yrittajan-palvelutarjotin/tekes</a><br>

					<a href="http://www.neogames.fi/wp-content/uploads/2013/10/Tekes_Peliteollisuus_kehityspolku_2013.pdf">http://www.neogames.fi/wp-content/uploads/2013/10/Tekes_Peliteollisuus_kehityspolku_2013.pdf</a><br>

					FIBAN /Finnish Business Angels Network: <a href="https://www.fiban.org/opas.html">https://www.fiban.org/opas.html</a><br>

					<a href="https://www.fiban.org/news/suomalaiset-aikaisen-vaiheen-kasvuyritykset-kerasivat-349-miljoonan-euron-ennatysmaisen-rahoituspotin-rahoituskanavat-murroksessa">https://www.fiban.org/news/suomalaiset-aikaisen-vaiheen-kasvuyritykset-kerasivat-349-miljoonan-euron-ennatysmaisen-rahoituspotin-rahoituskanavat-murroksessa</a><br>

					YRITTÄJÄT: <a href="https://www.yrittajat.fi/yrittajan-abc/perustietoa-yrittajyydesta-316159">https://www.yrittajat.fi/yrittajan-abc/perustietoa-yrittajyydesta-316159</a><br>

					Tekniikan Akateemiset (Yrittäjän palvelut) <a href="https://www.tek.fi/fi/jasenedut/yrittajan-palvelut">https://www.tek.fi/fi/jasenedut/yrittajan-palvelut</a><br>

					YRITYSPÖRSSI /Ostetaan ja myydään yrityksiä: <a href="https://www.yritysporssi.fi/">https://www.yritysporssi.fi/</a><br>

					Eezy (KEVYTYRITTÄJÄT): <a href="https://eezy.fi/">https://eezy.fi/</a><br>

					UKKO (KEVYTYRITTÄJIEN LASKUTUS): <a href="https://www.ukko.fi/">https://www.ukko.fi/</a></p>


				</div>
				<div class="col"></div>
			</div>
		<Social/>
		</div>
	</div>
</template>

<script>
import Social from '../../components/Social.vue'

export default {
name: 'Yrittajyys',
components: {
    Social
  }
}

</script>

<style type="text/css">
.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>